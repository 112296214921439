import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Parts of a PC",
  "date": "2020-11-07",
  "slug": "blog/parts-of-a-pc",
  "featuredImage": "./images/parts-of-a-pc.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`CPU (Processor)`}</h2>
    <p>{`CPU stands for Central Processing Unit. When picking parts for a new build, this is a good place to start. Of all the components, the CPU has the biggest effect on the overall performance of your computer. Things to look out for are:`}</p>
    <h4>{`cores`}</h4>
    <p>{`each core is an individual processing unit. Having more cores enables multitasking. Multitasking is necessary for running multiple programs, completing tasks in parallel, playing complex video games, and more. `}</p>
    <h4>{`clock speed`}</h4>
    <p>{`this value, measured in gigahertz (GHz) is how many internal operations the CPU can perform per second. A higher clock speed means the CPU can run more clock cycles, thus will perform calculations more quickly, making the computer faster.  `}</p>
    <p>{`We're `}<a parentName="p" {...{
        "href": "/hot-list/gaming/desktop-processors/Ryzen5000"
      }}>{`tracking the (NEW! and super sold out) Amd Ryzen 5000s`}</a></p>
    <hr></hr>
    <h2>{`Motherboard`}</h2>
    <p>{`The Motherboard is a printed circuit board that all other parts are attached to. This is the part which unifies the all the other components.
Each component must be compatible with the Motherboard, so be thoughtful while shopping. `}</p>
    <hr></hr>
    <h2>{`GPU (Graphics Card)`}</h2>
    <p>{`GPU stands for Graphics Processing Unit which handles both the computations necessary to create visual output, and the output of those visuals to your monitor.
There are two types of Graphics Cards`}</p>
    <h4>{`Integrated GPU`}</h4>
    <p>{`GPU integrated in the CPU.
light tasks, but not good for graphics heavy tasks.
output to the Motherboard's display adaptor`}</p>
    <h4>{`Discrete GPU`}</h4>
    <p>{`separate item on it's own printed circuit builrd that gets plugged into the Motherboard
We're `}<a parentName="p" {...{
        "href": "/hot-list/gaming/graphics-cards/nvidia-geforce-rtx-3090"
      }}>{`tracking the Nvidia 30 Series GPUs`}</a></p>
    <hr></hr>
    <h2>{`RAM`}</h2>
    <p>{`RAM stands for Random Access Memory and is a temporary storage on the computer. Reads and Writes happen very quickly, but everything will be lost when the machine is powered down. Every Motherboard will have 1, 2, 4, or 8 slots that supports a maximum of that many RAM Modules. Each Module can have anywhere between 4GB and 32GB of storage.
The more RAM, the better the computer will be at multitasking. `}</p>
    <p>{`Modern RAM comes in different versions - DDR3 and DDR4 being most common in 2020. DDR4 is the fastest current version of RAM. You will have to check your Motherboard capability to match the RAM you purchase. There are alos varying speeds of RAM that can impact system performance.`}</p>
    <hr></hr>
    <h2>{`Storage (SSD, HDD)`}</h2>
    <p>{`For a long term storage solution that persists even when the machine is powered down, there are three options. `}</p>
    <hr></hr>
    <h2>{`Cooling (CPU, Chassis)`}</h2>
    <p>{`CPU produces a lot of heat so you need something to cool it down. Some come with
otherwise, options- air cooling and water cooling`}</p>
    <hr></hr>
    <h2>{`PSU (Power Supply Unit)`}</h2>
    <p>{`A Power Supply Unit
The power supply unit delivers power from an electrical outlet to the computer parts. It is important to ensure your power supply can handle enough power for all of your parts. Checking for compatibility is as simple as adding the power loads of each part and comparing it to how much the power supply can support. The power supply should be able to support at least as much as is needed, but more will allow you to upgrade parts in the future. `}</p>
    <p>{`If you end up buying one of the `}<a parentName="p" {...{
        "href": "/hot-list/gaming/graphics-cards/nvidia-geforce-rtx-3090"
      }}>{`Nvidia 30 Series GPUs`}</a></p>
    <hr></hr>
    <h2>{`Case`}</h2>
    <p>{`Technically optional, the case is a housing for all of your computer components. Often they are designed for the components to be placed in specific areas, and come with holes and screws to attach them. Cases can be opened or closed. There is a huge variety in cases available -- check out some of our featured builds (coming soon!) to get some inspiration.`}</p>
    <hr></hr>
    <h2>{`Display device`}</h2>
    <p>{`Any monitor will do! `}</p>
    <hr></hr>
    <h2>{`Input Devices`}</h2>
    <p>{`Mouse
Keyboard - primary way to input and communicate with the computer`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      